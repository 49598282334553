import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as faceapi from 'face-api.js';

// SERVICES
import useWebSocket from '../../services/hooks/websocket/useWebSocket';
import useSession from '../../services/hooks/session/useSession';
import useTTS from '../../services/hooks/text-to-speech/useTTS';
import useVideoRecord from '../../services/hooks/video-record/useVideoRecord';
import useSettings from '../../services/hooks/settings/useSettings';

// COMPONENTS
import { VoiceRecorder } from '../../components/voice-recorder/VoiceRecorder';
import { ChatLog } from '../../components/chat-log/ChatLog';
import Timer from '../../components/timer/Timer';

// TYPES
import { ChatLogType } from './Chat.types';
import { AudioPlayer } from '../../components/audio-player/AudioPlayer';
import { useNavigate } from 'react-router-dom';
import { useConfirmOnPageExit } from '../../services/confirmation/useConfirmationToLeave';
import { UploadProgressModal } from '../../components/upload-modal/uploadModal';
import classNames from 'classnames';
import TextModal from '../../components/text-modal/textModal';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import useDialogue from '../../services/hooks/dialogue/useDialogue';

export default function ChatSession() {
  useConfirmOnPageExit(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAddComment, setShowAddComment] = useState<boolean>(false);
  const [chatLog, setChatLog] = useState<ChatLogType[]>([]);
  const navigate = useNavigate();

  const [faceDetected, setFaceDetected] = useState(false);
  const [currentSession, setCurrentSesion] = useState<string>();
  const { postDialog } = useDialogue();

  const { settings, updateShowChatText } = useSettings();

  const { enableVoice, voiceType, botType, showPatientText, showChatText } = settings;

  const audioRef: React.RefObject<HTMLAudioElement> = useRef(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const { sessionId, startSession, stopSession, updateSession } = useSession(
    isLoading,
    setIsLoading,
  );

  const onUploaded = useCallback(() => {
    setShowAddComment(true);
  }, [currentSession, updateSession]);

  let {
    recording,
    stopRecording,
    handleStartRecording,
    uploadProgress,
    showUploadModal,
    recordingStartTime,
  } = useVideoRecord(videoRef, mediaRecorderRef, onUploaded);

  const handleOnSkip = async () => {
    setShowAddComment(false);
    navigate('/patient');
  };

  const handleOnSave = async (text: string) => {
    setShowAddComment(false);
    try {
      await updateSession({
        sessionId: currentSession!,
        comment: text,
      });
      toast.success('Comments added successfully', {
        position: 'top-center',
      });
    } catch (e: any) {
      toast.error(`Comment not saved, ${e.toString()}`, {
        position: 'top-center',
      });
    } finally {
      navigate('/patient');
    }
  };

  useEffect(() => {
    let faceInterval: NodeJS.Timer;

    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + '/models';

      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]).then(() => {
        faceInterval = setInterval(async () => {
          if (!videoRef.current) {
            console.log('videoRef.current');
            return;
          }
          const detections = await faceapi.detectAllFaces(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions(),
          );
          setFaceDetected(!!detections.length);
        }, 500);
      });
    };
    loadModels();
    return () => {
      clearInterval(faceInterval);
    };
  }, []);

  const { handleMessage, audio, responseMessage } = useTTS(setChatLog, enableVoice, voiceType);
  const { sendMessage } = useWebSocket(handleMessage, sessionId, isLoading, botType, setIsLoading);

  const enhancedStartSession = useCallback(async () => {
    setChatLog([]);
    const session = await startSession(settings);
    setCurrentSesion(session);
    await handleStartRecording(session);
  }, [startSession, handleStartRecording]);

  useEffect(() => {
    if (currentSession && recordingStartTime) {
      updateSession({
        sessionId: currentSession,
        start_timestamp: recordingStartTime,
      });
    }
  }, [currentSession, recordingStartTime, updateSession]);

  const enhancedStopSession = useCallback(() => {
    stopRecording();
    stopSession();
  }, [stopRecording, stopSession, settings]);

  const [responseStartTime, setResponseStartTime] = useState<string>();

  useEffect(() => {
    if (responseMessage && !settings.enableVoice) {
      const startTime = new Date().toISOString();
      setResponseStartTime(startTime);
      postDialog({
        text: responseMessage,
        speaker: 'bot',
        sessionId,
        speechStartedAt: startTime,
        speechEndedAt: new Date().toISOString(),
      });
    }
  }, [responseMessage]);

  useEffect(() => {
    enhancedStartSession();
    return () => {
      stopRecording();
    };
  }, []);

  return (
    <div className='flex flex-col h-full max-h-full overflow-auto p-4 container mx-auto border-0'>
      <UploadProgressModal progress={uploadProgress} show={showUploadModal} />
      {showAddComment && (
        <TextModal
          title='add comments or skip'
          handleOnSave={handleOnSave}
          handleOnSkip={handleOnSkip}
        />
      )}
      <div className='w-3/4 mx-auto bg-white flex-grow rounded-md shadow p-6 max-h-full overflow-auto'>
        <div className='space-y-4 max-h-full overflow-auto h-full'>
          <div className='flex flex-row gap-2 items-center w-full space-x-10'>
            <div className='border border-yellow-500 text-red-500 bg-yellow-100 p-3 rounded-md flex-grow'>
              <p>
                <b>NOT</b> close or <b>NOT</b> reload this page before end the session
              </p>
            </div>
            {recording && <Timer />}
          </div>
          <div className='flex space-x-3 max-h-full overflow-auto'>
            <div className='flex-shrink-0 h-full'>
              <div className='relative h-[150px] w-[200px]'>
                <video ref={videoRef} autoPlay muted className={classNames('mx-auto rounded-md')} />
                {recording && videoRef.current && (
                  <img
                    alt='face status'
                    className='absolute bottom-1 right-1 w-8'
                    src={faceDetected ? '/face-detected.png' : '/face-not-detected.png'}
                  />
                )}
              </div>
            </div>
            <div className='h-full overflow-auto max-h-full'>
              <ChatLog chatLog={chatLog} isLoading={isLoading} />
              {enableVoice && (
                <AudioPlayer audio={audio} sessionId={sessionId} message={responseMessage} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='mt-6 flex justify-between items-center'>
        <VoiceRecorder
          sendMessage={sendMessage}
          setChatLog={setChatLog}
          response={responseMessage}
          sessionId={sessionId}
          isLoading={isLoading}
        />
        <button
          onClick={enhancedStopSession}
          className='bg-red-500 hover:bg-red-600 text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center'
        >
          End Session
        </button>
      </div>
    </div>
  );
}
