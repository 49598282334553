import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

// SERVICES
import useAxios from '../../axios/useAxios';
import useSettings from '../settings/useSettings';

// TYPES
import { TTSHook } from './useTTS.types';
import { ChatLogType } from '../../../pages/chat/Chat.types';

const useTTS = (
  setChatLog: React.Dispatch<React.SetStateAction<ChatLogType[]>>,
  enableVoice: boolean,
  voiceType: 'female' | 'male',
): TTSHook => {
  const [audio, setAudio] = useState<string>('');
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);

  const { settings, updateShowChatText } = useSettings();
  const { showChatText } = settings;

  const handleMessage = useCallback(
    async (message: string): Promise<void> => {
      if (isProcessing) return;

      setIsProcessing(true);
      setChatLog((prev) => [{ sender: 'Bot', message, id: uuidv4() }, ...prev]);
      setResponseMessage(message);

      if (!enableVoice) {
        setIsProcessing(false);
        return;
      }

      try {
        const res = await axios.request({
          method: 'POST',
          headers: {
            accept: 'audio/mpeg',
            'content-type': 'application/json',
            'xi-api-key': process.env.REACT_APP_TTS_API_KEY,
          },
          url: `https://api.elevenlabs.io/v1/text-to-speech/${
            voiceType === 'female' ? '21m00Tcm4TlvDq8ikWAM' : '2EiwWnXFnvU5JabPnv8n'
          }`,
          data: {
            text: message,
            voice_settings: {
              stability: 0,
              similarity_boost: 0,
            },
          },
          responseType: 'arraybuffer',
        });

        const audioBlob = new Blob([res.data], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudio(audioUrl);
      } catch (error) {
        console.error('Error getting TTS:', error);
        if (!showChatText) {
          updateShowChatText(true);
        }
      } finally {
        setIsProcessing(false);
      }
    },
    [enableVoice, setChatLog, showChatText, updateShowChatText, voiceType, isProcessing],
  );

  return {
    audio,
    responseMessage,
    handleMessage,
  };
};

export default useTTS;
