import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faTimes,
  faChevronLeft,
  faMagnifyingGlassChart,
  faComments,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import ReactJson from 'react-json-view';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import SolidGauge from 'highcharts/modules/solid-gauge';
import Heatmap from 'highcharts/modules/heatmap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import useSession from '../../services/hooks/session/useSession';
import { SessionDTO, TextAnalysis } from '../../services/hooks/session/useSession.types';
import { useKeycloak } from '@react-keycloak/web';
import useUserProfile from '../../services/hooks/user-profile/useUserProfile';
import VideoPlayer from '../../components/video-player/VideoPlayer';
import { calculateEmotionalVariability } from '../../utils/evi';
import { calculateOverallMood } from '../../utils/sentiment';
import ChatWidget from '../../components/chat/ChatWidget';
import { TextAnalyticsTable } from '../../components/text-analytics/TextAnalyticsTable';
import LoadingScreen from '../../components/loading/LoadingScreen';

import 'react-tooltip/dist/react-tooltip.css';
import { generateFacialAnalyses } from '../../utils/generateFacialAnalysis';
import { generateAudioAnalyses } from '../../utils/generateAudioAnalysis';

// Initialize the solid-gauge module
highchartsMore(Highcharts);
SolidGauge(Highcharts);
Heatmap(Highcharts);

const formatDuration = (seconds?: number): string => {
  if (!seconds) return '-';

  // Round to nearest integer to avoid floating point issues
  seconds = Math.round(seconds);

  if (seconds < 60) {
    return `${seconds}s`;
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  if (hours > 0) {
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  }

  return `${minutes}m ${remainingSeconds}s`;
};

const SessionDetailsPage = () => {
  const { keycloak } = useKeycloak();
  const [session, setSession] = useState<SessionDTO | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { sessionId } = useParams();
  const { getSession, extractTextInsights, chat } = useSession(false, () => {});
  const { profileData, getUserProfile } = useUserProfile();
  const [showRawData, setShowRawData] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState<TextAnalysis | null>(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [nextQuestions, setNextQuestions] = useState('');
  const [behaviorNotes, setBehaviorNotes] = useState('');

  const getCurrentSession = async (sessionId: string) => {
    try {
      setIsLoading(true);
      const ses = await getSession(sessionId);
      if (!ses) return;
      console.log(ses.textAnalyses.length);
      ses.facialAnalyses = generateFacialAnalyses(ses.textAnalyses?.length || 0, ses.sessionId);
      ses.audioAnalyses = generateAudioAnalyses(ses.textAnalyses?.length || 0, ses.sessionId);
      setSession(ses);
      if (ses?.userId) {
        await getUserProfile(ses.userId);
      }
    } catch (error) {
      console.error('Error loading session:', error);
      toast.error('Failed to load session data');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!keycloak.authenticated) {
      return;
    }
    getCurrentSession(sessionId!);
  }, [sessionId, keycloak.authenticated]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && showRawData) {
        setShowRawData(false);
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => window.removeEventListener('keydown', handleEscKey);
  }, [showRawData]);

  if (isLoading) {
    return <LoadingScreen title='Loading Session' subtitle='Preparing session insights...' />;
  }

  if (!session) {
    return (
      <div className='flex items-center justify-center min-h-screen bg-gray-100'>
        <div className='text-center'>
          <h2 className='text-2xl font-semibold text-gray-700 mb-2'>Session Not Found</h2>
          <p className='text-gray-500'>
            The requested session could not be found or you don't have access to it.
          </p>
          <Link to='/' className='mt-4 inline-block text-blue-500 hover:text-blue-600'>
            Return to Home
          </Link>
        </div>
      </div>
    );
  }

  const renderTags = () => {
    const tags = session?.comment?.split(',') || [];
    const visibleTags = tags.slice(0, 3);
    const remainingTags = tags.slice(3);
    const remainingCount = remainingTags.length;

    return (
      <div className='flex gap-1'>
        {visibleTags.map((tag, index) => (
          <span key={index} className='bg-gray-200 text-gray-700 px-2 py-1 text-sm rounded-full'>
            {tag}
          </span>
        ))}
        {remainingCount > 0 && (
          <span
            className='bg-gray-200 text-gray-700 px-2 py-1 text-sm rounded-full cursor-pointer'
            data-tooltip-id='tags-tooltip'
            data-tooltip-content={remainingTags.join(', ')}
          >
            +{remainingCount}
          </span>
        )}
      </div>
    );
  };

  const getSentimentChartOptions = () => {
    // Group sentiments from text analyses
    const sentimentCounts =
      session?.textAnalyses?.reduce((acc: { [key: string]: number }, analysis) => {
        if (analysis.sentiment) {
          acc[analysis.sentiment] = (acc[analysis.sentiment] || 0) + 1;
        }
        return acc;
      }, {}) || {};

    // Convert to Highcharts series data format
    const seriesData = Object.entries(sentimentCounts).map(([sentiment, count]) => ({
      name: sentiment,
      y: count,
    }));

    return {
      chart: {
        type: 'pie',
        height: '300px',
      },
      title: {
        text: 'Sentiment Distribution',
      },
      plotOptions: {
        pie: {
          innerSize: '50%',
          depth: 45,
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.1f}%',
          },
        },
      },
      series: [
        {
          name: 'Sentiments',
          data: seriesData,
        },
      ],
    };
  };

  const getSentimentTimelineOptions = () => {
    if (!session?.textAnalyses) return {};

    // Sort analyses by start time
    const sortedAnalyses = [...session.textAnalyses].sort(
      (a, b) => new Date(a?.startTime).getTime() - new Date(b?.startTime).getTime(),
    );

    // Define sentiment values
    const sentimentValues = {
      Positive: 8.5,
      Neutral: 5.0,
      Negative: 2.5,
    };

    // Convert sentiment to numeric value
    const sentimentToValue = (sentiment: string): number => {
      return sentimentValues[sentiment as keyof typeof sentimentValues] || sentimentValues.Neutral;
    };

    // Process analyses to get mood data
    const moodData = sortedAnalyses.map((analysis, index) => {
      const sentimentValue = sentimentToValue(analysis.sentiment);

      // Determine color based on sentiment value
      let color = '#f1c40f'; // Default yellow for neutral
      if (sentimentValue >= 7) color = '#2ecc71'; // Green for positive
      else if (sentimentValue < 4) color = '#e74c3c'; // Red for negative

      return {
        x: analysis.segment,
        y: sentimentValue,
        color: color,
        transcript: analysis.transcript,
      };
    });

    return {
      chart: {
        type: 'spline',
        height: '300px',
        style: {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        },
      },
      title: {
        text: 'Mood Level Over Time',
      },
      xAxis: {
        type: 'linear',
        title: {
          text: 'Segment',
        },
        min: 0,
        max: sortedAnalyses.length - 1,
        tickInterval: 1,
        labels: {
          formatter: function (this: any): string {
            const analysis = sortedAnalyses[this.value];
            if (!analysis) return String(this.value);
            const date = dayjs(analysis.startTime).format('MM/DD/YYYY');
            return `<div style="text-align: center">${this.value}<br/>(${date})</div>`;
          },
          useHTML: true,
          align: 'center',
        },
      },
      yAxis: {
        title: {
          text: 'Mood Level',
        },
        min: 0,
        max: 10,
        tickInterval: 2,
        plotBands: [
          {
            from: 0,
            to: 4,
            color: 'rgba(231, 76, 60, 0.1)', // Light red
            label: {
              text: 'Negative',
              style: { color: '#e74c3c' },
            },
          },
          {
            from: 4,
            to: 7,
            color: 'rgba(241, 196, 15, 0.1)', // Light yellow
            label: {
              text: 'Neutral',
              style: { color: '#f1c40f' },
            },
          },
          {
            from: 7,
            to: 10,
            color: 'rgba(46, 204, 113, 0.1)', // Light green
            label: {
              text: 'Positive',
              style: { color: '#2ecc71' },
            },
          },
        ],
      },
      tooltip: {
        formatter: function (this: any): string {
          const point = this.point;
          const analysis = sortedAnalyses.find((a) => a.segment === point.x);
          if (!analysis) return '';

          return `
            <div style="min-width: 200px">
              <b>Segment:</b> ${analysis.segment}<br/>
              <b>Primary Emotion:</b> ${analysis.primaryEmotion}<br/>
              <b>Extended Emotion:</b> ${analysis.extentedEmotion}<br/>
              <b>Intensity:</b> ${analysis.emotionIntensity}<br/>
              <b>Sentiment:</b> ${analysis.sentiment}<br/>
              <div style="color: #666; margin-top: 8px;">
                ${analysis.sentimentInferenceDescription || ''}
              </div>
            </div>
          `;
        },
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
            radius: 6,
            symbol: 'circle',
          },
        },
      },
      series: [
        {
          name: 'Mood Level',
          data: moodData,
        },
      ],
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    };
  };

  const getEmotionHeatmapOptions = () => {
    // Transform data for heatmap
    if (!session || !session.textAnalyses.length) return {};

    const emotionData = session.textAnalyses.map((analysis) => ({
      x: analysis.segment,
      y: analysis.primaryEmotion,
      value: analysis.emotionIntensity,
      intensity: analysis.emotionIntensity,
    }));

    console.log(emotionData);
    const uniqueEmotions = Array.from(new Set(session.textAnalyses.map((a) => a.primaryEmotion)));
    const intensityValues = session.textAnalyses.map((a) => a.emotionIntensity);
    const minIntensity = Math.min(...intensityValues);
    const maxIntensity = Math.max(...intensityValues);
    const maxSegment = Math.max(...session.textAnalyses.map((a) => a.segment));

    return {
      chart: {
        type: 'heatmap',
        height: '400px',
        style: {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        },
      },
      title: {
        text: 'Emotion Intensity Heatmap by Segment and Emotion',
      },
      xAxis: {
        type: 'linear',
        title: {
          text: 'Segment',
        },
        min: 0,
        max: maxSegment,
        labels: {
          formatter: function (this: any): string {
            const analysis = session.textAnalyses.find((a) => a.segment === this.value);
            if (!analysis) return String(this.value);
            const date = dayjs(analysis.startTime).format('MM/DD/YYYY');
            return `<div style="text-align: center">${this.value}<br/>(${date})</div>`;
          },
          useHTML: true,
          align: 'center',
          style: {
            fontSize: '12px',
          },
        },
      },
      yAxis: {
        categories: uniqueEmotions,
        title: {
          text: 'Primary Emotion',
          offset: 30,
        },
        labels: {
          align: 'center', // Changed from 'left' to 'center'
          x: -10,
          y: 6, // Added y offset to help with centering
          rotation: -90,
          style: {
            fontSize: '12px',
          },
        },
      },
      colorAxis: {
        min: minIntensity,
        max: maxIntensity,
        stops: [
          [0, '#FFEDA0'], // Light yellow for low intensity
          [0.3, '#7FCD91'], // Light green
          [0.6, '#31A354'], // Green
          [0.8, '#2171B5'], // Light blue
          [1, '#08519C'], // Dark blue for high intensity
        ],
        labels: {
          format: '{value}',
          style: {
            fontSize: '12px',
          },
        },
        tickColor: 'gray',
        tickWidth: 1,
        layout: 'vertical',
        height: '85%',
        y: 50,
        tickPosition: 'inside',
        reversed: false,
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        margin: 10,
      },
      series: [
        {
          name: 'Emotion Intensity',
          borderWidth: 1,
          data: emotionData.map((d) => ({
            x: d.x,
            y: uniqueEmotions.indexOf(d.y),
            value: d.intensity,
          })),
          dataLabels: {
            enabled: true,
            format: '{point.value:.1f}',
          },
        },
      ],
      tooltip: {
        formatter: function (this: any): string {
          const point = this.point;
          const emotion = uniqueEmotions[point.y];
          const analysis = session.textAnalyses.find((a) => a.segment === point.x);
          return `
            <div style="min-width: 150px">
              <b>Segment:</b> ${point.x}<br/>
              <b>Emotion:</b> ${emotion}<br/>
              <b>Intensity:</b> ${point.value}<br/>
              <b>Description:</b> ${analysis?.primaryEmotionInferenceDescription || 'Not set'}<br/>
            </div>
          `;
        },
      },
    };
  };

  const getAudioAnalysisChartOptions = () => {
    const sortedAnalyses =
      session?.audioAnalyses?.sort(
        (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
      ) || [];

    return {
      chart: {
        type: 'spline',
        height: '400px',
        style: {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        },
      },
      title: {
        text: 'Audio Analysis Metrics Over Time',
      },
      xAxis: {
        type: 'linear',
        title: {
          text: 'Segment',
        },
        min: 0,
        max: sortedAnalyses.length - 1,
        tickInterval: 1,
        labels: {
          formatter: function (this: any): string {
            const analysis = sortedAnalyses[this.value];
            if (!analysis) return String(this.value);
            const date = dayjs(analysis.startTime).format('MM/DD/YYYY');
            return `<div style="text-align: center">${this.value}<br/>(${date})</div>`;
          },
          useHTML: true,
          align: 'center',
        },
      },
      yAxis: {
        title: {
          text: 'Value',
        },
      },
      tooltip: {
        shared: true,
        crosshairs: true,
        formatter: function (this: any): string {
          const units: { [key: string]: string } = {
            'Speaking Rate': 'w/m',
            'Average Pitch': 'Hz',
            Loudness: 'dB',
            'Speech Duration': 's',
            'Silence Duration': 's',
          };

          return this.points?.reduce(
            (s: string, point: { color: any; series: { name: any }; y: number }) => {
              const unit = units[point.series.name] || '';
              return (
                s +
                `<br/><span style="color:${point.color}">\u25CF</span> ${
                  point.series.name
                }: <b>${point.y.toFixed(2)}${unit ? ` ${unit}` : ''}</b>`
              );
            },
            `<b>Segment ${this.x + 1}</b>`,
          );
        },
      },
      series: [
        {
          name: 'Speaking Rate',
          data: sortedAnalyses.map((a, i) => ({
            x: i,
            y: a.speakingRate,
          })),
          color: '#2ecc71',
        },
        {
          name: 'Average Pitch',
          data: sortedAnalyses.map((a, i) => ({
            x: i,
            y: a.averagePitch,
          })),
          color: '#e74c3c',
        },
        {
          name: 'Loudness',
          data: sortedAnalyses.map((a, i) => ({
            x: i,
            y: a.loudness,
          })),
          color: '#f1c40f',
        },
        {
          name: 'Speech Duration',
          data: sortedAnalyses.map((a, i) => ({
            x: i,
            y: a.speechDuration,
          })),
          color: '#3498db',
        },
        {
          name: 'Silence Duration',
          data: sortedAnalyses.map((a, i) => ({
            x: i,
            y: a.silenceDuration,
          })),
          color: '#9b59b6',
        },
      ],
      plotOptions: {
        series: {
          marker: {
            enabled: true,
          },
        },
      },
    };
  };

  const getFacialAnalysisChartOptions = () => {
    if (!session?.facialAnalyses) return {};

    const sortedAnalyses = [...session.facialAnalyses].sort(
      (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
    );

    return {
      chart: {
        type: 'spline',
        height: '400px',
        style: {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        },
      },
      title: {
        text: 'Facial Analysis Metrics Over Time',
      },
      xAxis: {
        type: 'linear',
        title: {
          text: 'Segment',
        },
        min: 0,
        max: sortedAnalyses.length - 1,
        tickInterval: 1,
        labels: {
          formatter: function (this: any): string {
            const analysis = sortedAnalyses[this.value];
            if (!analysis) return String(this.value);
            const date = dayjs(analysis.startTime).format('MM/DD/YYYY');
            return `<div style="text-align: center">${this.value}<br/>(${date})</div>`;
          },
          useHTML: true,
          align: 'center',
        },
      },
      yAxis: {
        title: {
          text: 'Value',
        },
      },
      tooltip: {
        shared: true,
        crosshairs: true,
        formatter: function (this: any): string {
          const units: { [key: string]: string } = {
            'Blink Rate': 'b/m',
            'Smile Rate': '%',
            'Eye Contact': '%',
            'Head Movement': '°/s',
            'Face Direction': '°',
          };

          return this.points?.reduce(
            (s: string, point: { color: any; series: { name: any }; y: number }) => {
              const unit = units[point.series.name] || '';
              return (
                s +
                `<br/><span style="color:${point.color}">\u25CF</span> ${
                  point.series.name
                }: <b>${point.y.toFixed(2)}${unit ? ` ${unit}` : ''}</b>`
              );
            },
            `<b>Segment ${this.x + 1}</b>`,
          );
        },
      },
      series: [
        {
          name: 'Blink Rate',
          data: sortedAnalyses.map((a, i) => ({
            x: i,
            y: a.blinkRate,
          })),
        },
        {
          name: 'Smile Rate',
          data: sortedAnalyses.map((a, i) => ({
            x: i,
            y: a.smileRate,
          })),
        },
        {
          name: 'Eye Contact',
          data: sortedAnalyses.map((a, i) => ({
            x: i,
            y: a.eyeContact,
          })),
        },
      ],
    };
  };

  const getEVIGaugeOptions = (value: number) => ({
    chart: {
      type: 'solidgauge',
      height: '200px',
      backgroundColor: 'transparent',
    },
    title: {
      text: 'Emotional Variability Index',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
    pane: {
      center: ['50%', '65%'],
      size: '100%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      stops: [
        [0.1, '#55BF3B'], // green
        [0.5, '#DDDF0D'], // yellow
        [0.9, '#DF5353'], // red
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      min: 0,
      max: 100,
      title: {
        text: '',
        y: -70,
      },
      labels: {
        y: 16,
      },
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: -25,
          borderWidth: 0,
          useHTML: true,
          format:
            '<div style="text-align:center"><span style="font-size:25px;font-weight:bold">{y}</span></div>',
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'EVI',
        data: [value],
        dataLabels: {
          format:
            '<div style="text-align:center"><span style="font-size:25px;font-weight:bold">{y}</span></div>',
        },
      },
    ],
  });

  const getEVIExplanation = (value: number) => {
    if (value >= 80) {
      return 'High emotional variability: Significant fluctuations in emotional intensity and frequent emotion changes.';
    } else if (value >= 50) {
      return 'Moderate emotional variability: Normal range of emotional changes and intensity variations.';
    } else {
      return 'Low emotional variability: Consistent emotional state with minimal changes in intensity.';
    }
  };

  // Calculate emotional variability index
  const emotionalVariability = session?.textAnalyses
    ? calculateEmotionalVariability(session.textAnalyses)
    : {
        index: 0,
        details: {
          avgIntensityChange: 0,
          emotionChanges: 0,
          timeWeightedChange: 0,
        },
      };

  return (
    <div className='p-4 max-w-8xl mx-auto'>
      <div className='flex items-center gap-3 mb-4'>
        <Link
          to={`/users/${session?.userId}/profile`}
          className='text-gray-600 hover:text-gray-800 transition-colors'
          title='Go to patient profile'
        >
          <FontAwesomeIcon icon={faChevronLeft} size='lg' />
        </Link>
        <h1 className='text-2xl font-bold flex-1'>Session Details</h1>
        <button
          onClick={async () => {
            if (sessionId) {
              toast.info('Starting text insights extraction. This may take a while...');
              const response = await extractTextInsights(sessionId);
              if (response) {
                toast.success('Text insights extracted successfully');
              }
            }
          }}
          className='flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors'
          title='Extract Text Insights'
        >
          <FontAwesomeIcon icon={faMagnifyingGlassChart} />
          <span>Extract Insights</span>
        </button>
        <button
          onClick={() => setShowRawData(true)}
          className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2'
        >
          Show Raw Data
          <FontAwesomeIcon icon={faEye} />
        </button>
        <button
          onClick={() => setShowFeedbackModal(true)}
          className='px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center space-x-2'
        >
          <FontAwesomeIcon icon={faComments} />
          <span>Feedback</span>
        </button>
      </div>

      <div className='bg-white shadow rounded-lg p-6 mb-6'>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
          <div className='col-span-1'>
            {session?.recordingLink && <VideoPlayer src={session?.recordingLink} />}
          </div>

          <div className='col-span-2'>
            <div className='grid grid-cols-3 gap-4'>
              <div className='bg-gray-50 p-4 rounded-lg'>
                <div className='text-sm text-gray-500'>User</div>
                <div className='text-lg font-semibold'>
                  {profileData ? `${profileData.fullName}` : session?.userId}
                </div>
              </div>
              <div className='bg-gray-50 p-4 rounded-lg'>
                <div className='text-sm text-gray-500'>Session Date</div>
                <div className='text-lg font-semibold'>
                  {dayjs(new Date(session?.recordedDate || '')).format('MM-DD-YYYY HH:mm')}
                </div>
              </div>
              <div className='bg-gray-50 p-4 rounded-lg'>
                <div className='text-sm text-gray-500'>Duration</div>
                <div className='text-lg font-semibold'>{formatDuration(session?.duration)}</div>
              </div>
              <div className='bg-gray-50 p-4 rounded-lg col-span-2'>
                <div className='text-sm text-gray-500'>Tags</div>
                <div className='text-lg'>{renderTags()}</div>
              </div>
              <div className='bg-gray-50 p-4 rounded-lg'>
                <div className='text-sm text-gray-500'>Overall Mood</div>
                <div className='text-lg font-semibold'>
                  {session?.textAnalyses && (
                    <span
                      style={{
                        color:
                          calculateOverallMood(session.textAnalyses) === 'Positive'
                            ? '#55BF3B'
                            : calculateOverallMood(session.textAnalyses) === 'Negative'
                            ? '#DF5353'
                            : calculateOverallMood(session.textAnalyses) === '-'
                            ? '#666'
                            : '#DDDF0D',
                        fontWeight: 'bold',
                      }}
                    >
                      {calculateOverallMood(session.textAnalyses)}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showRawData && (
          <div className='fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-50'>
            <div className='fixed inset-y-0 right-0 w-2/3 bg-white shadow-xl overflow-y-auto'>
              <div className='sticky top-0 bg-white border-b border-gray-200 px-4 py-3 flex justify-between items-center'>
                <h3 className='text-lg font-semibold'>Raw Session Data</h3>
                <button
                  onClick={() => setShowRawData(false)}
                  className='text-gray-500 hover:text-gray-700'
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className='p-4'>
                <ReactJson
                  src={
                    session
                      ? {
                          ...session,
                          textAnalyses: [...session.textAnalyses].sort(
                            (a, b) => a.segment - b.segment,
                          ),
                          audioAnalyses: [...session.audioAnalyses].sort(
                            (a, b) => a.segment - b.segment,
                          ),
                          facialAnalyses: [...session.facialAnalyses].sort(
                            (a, b) => a.segment - b.segment,
                          ),
                        }
                      : {}
                  }
                />
              </div>
            </div>
          </div>
        )}

        <hr className='separator' />
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
          <div className='bg-white rounded-lg shadow p-4'>
            <HighchartsReact
              highcharts={Highcharts}
              options={getEVIGaugeOptions(emotionalVariability.index)}
            />
            <div className='space-y-2 text-sm'>
              <div className='flex justify-between'>
                <span>Average Intensity Change:</span>
                <span className='font-medium'>
                  {emotionalVariability.details.avgIntensityChange} points
                </span>
              </div>
              <div className='flex justify-between'>
                <span>Emotion Type Changes:</span>
                <span className='font-medium'>
                  {emotionalVariability.details.emotionChanges} times
                </span>
              </div>
              <div className='flex justify-between'>
                <span>Time-Weighted Change Rate:</span>
                <span className='font-medium'>
                  {emotionalVariability.details.timeWeightedChange} points/sec
                </span>
              </div>
            </div>
          </div>
          <div className='p-4 border border-gray-200 rounded shadow'>
            <HighchartsReact highcharts={Highcharts} options={getSentimentChartOptions()} />
          </div>
          <div className='bg-white rounded-lg shadow p-4'>
            <div className='w-full h-[300px]'>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  ...getSentimentTimelineOptions(),
                  chart: {
                    ...getSentimentTimelineOptions().chart,
                    backgroundColor: 'transparent',
                  },
                }}
              />
            </div>
          </div>
          <div className='bg-white rounded-lg shadow p-4'>
            <HighchartsReact highcharts={Highcharts} options={getEmotionHeatmapOptions()} />
          </div>
          <div className='bg-white rounded-lg shadow p-4'>
            <HighchartsReact highcharts={Highcharts} options={getAudioAnalysisChartOptions()} />
          </div>
          <div className='bg-white rounded-lg shadow p-4'>
            <HighchartsReact highcharts={Highcharts} options={getFacialAnalysisChartOptions()} />
          </div>
        </div>

        {/* Text Analytics Table */}
        {session?.textAnalyses && <TextAnalyticsTable textAnalyses={session.textAnalyses} />}

        {/* Chat Section */}
        <ChatWidget
          sessionId={sessionId!}
          onSendMessage={(message, history) => chat(sessionId!, message, history)}
        />

        {/* Context Summary Section */}
        {selectedSegment && (
          <div className='bg-white p-4 rounded-lg shadow mb-8'>
            <h3 className='text-lg font-semibold mb-4'>Context Summary for Selected Segment</h3>
            <div className='space-y-6'>
              <div>
                <h4 className='font-medium mb-2'>Transcript</h4>
                <p className='text-gray-700'>{selectedSegment.transcript}</p>
              </div>

              <div className='grid grid-cols-2 gap-4'>
                <div>
                  <h4 className='font-medium mb-2'>Emotions</h4>
                  <div className='space-y-2'>
                    <p>
                      <span className='font-medium'>Primary:</span>{' '}
                      <span className='text-gray-700'>{selectedSegment.primaryEmotion}</span>
                    </p>
                    <p>
                      <span className='font-medium'>Extended:</span>{' '}
                      <span className='text-gray-700'>{selectedSegment.extentedEmotion}</span>
                    </p>
                    <p>
                      <span className='font-medium'>Intensity:</span>{' '}
                      <span className='text-gray-700'>{selectedSegment.emotionIntensity}</span>
                    </p>
                  </div>
                </div>

                <div>
                  <h4 className='font-medium mb-2'>Sentiment Analysis</h4>
                  <div className='space-y-2'>
                    <p>
                      <span className='font-medium'>Sentiment:</span>{' '}
                      <span className='text-gray-700'>{selectedSegment.sentiment}</span>
                    </p>
                    <p>
                      <span className='font-medium'>Emotion Tone:</span>{' '}
                      <span className='text-gray-700'>{selectedSegment.emotionBasedTone}</span>
                    </p>
                    <p>
                      <span className='font-medium'>Style Tone:</span>{' '}
                      <span className='text-gray-700'>{selectedSegment.styleBasedTone}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <h4 className='font-medium mb-2'>Context</h4>
                <p className='text-gray-700'>{selectedSegment.contextDescription}</p>
                {selectedSegment.primaryEmotionInferenceDescription && (
                  <p className='mt-2 text-gray-600 italic'>
                    {selectedSegment.primaryEmotionInferenceDescription}
                  </p>
                )}
              </div>

              {selectedSegment.attitudeBasedTone && (
                <div>
                  <h4 className='font-medium mb-2'>Attitude Tone</h4>
                  <p className='text-gray-700'>{selectedSegment.attitudeBasedTone}</p>
                </div>
              )}
            </div>
          </div>
        )}
        {showRawData && (
          <div className='fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-50'>
            <div className='absolute inset-y-0 right-0 w-2/3 bg-white shadow-xl overflow-y-auto'>
              <div className='sticky z-10 top-0 bg-white border-b border-gray-200 px-4 py-3 flex justify-between items-center'>
                <h3 className='text-lg font-semibold'>Raw Session Data</h3>
                <button
                  onClick={() => setShowRawData(false)}
                  className='text-gray-500 hover:text-gray-700'
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className='p-4'>
                <ReactJson src={session || {}} />
              </div>
            </div>
          </div>
        )}
        {showFeedbackModal && (
          <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
            <div className='bg-white rounded-lg p-6 w-[600px] max-w-[90vw]'>
              <div className='flex justify-between items-center mb-4'>
                <h2 className='text-xl font-semibold'>Session Feedback</h2>
                <button
                  onClick={() => setShowFeedbackModal(false)}
                  className='text-gray-500 hover:text-gray-700'
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div className='space-y-4'>
                <div>
                  <label className='block text-sm font-medium text-gray-700 mb-1'>
                    Tone for next session
                  </label>
                  <textarea
                    value={nextQuestions}
                    onChange={(e) => setNextQuestions(e.target.value)}
                    className='w-full h-32 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                    placeholder='Enter the desired tone and communication style for the next session...'
                  />
                </div>
                <div>
                  <label className='block text-sm font-medium text-gray-700 mb-1'>
                    Key topics to cover
                  </label>
                  <textarea
                    value={behaviorNotes}
                    onChange={(e) => setBehaviorNotes(e.target.value)}
                    className='w-full h-32 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                    placeholder='Enter key topics to cover in the next session...'
                  />
                </div>
                <div className='flex justify-end space-x-3 mt-6'>
                  <button
                    onClick={() => setShowFeedbackModal(false)}
                    className='px-4 py-2 border rounded-lg hover:bg-gray-50'
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      // TODO: Implement feedback submission
                      toast.success('Feedback saved successfully');
                      setShowFeedbackModal(false);
                    }}
                    className='px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <Tooltip id='tooltip' place='top' />
        <Tooltip id='tags-tooltip' place='top' />
      </div>
    </div>
  );
};

export default SessionDetailsPage;
