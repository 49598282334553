import { useCallback, useState } from 'react';

import useAxios from '../../axios/useAxios';

import { ChatResponse, SessionDTO, SessionHook, SessionResponse } from './useSession.types';
import { useKeycloak } from '@react-keycloak/web';
import { UpdateSessions } from './update-sessions.interface';
import { ProcessSession } from './process-sessions.interface';
import {
  IResponseAudioFeaturesList,
  IResponseAudioList,
  IResponseBlinkDetectionList,
  IResponseFacialList,
  IResponseTextList,
  IRobertaEmotion,
} from './emotions.interface';
import { SettingsType } from '../../../context/settings/settingsContext.types';

const useSession = (isLoading: boolean, setIsLoading: (newState: boolean) => void): SessionHook => {
  const axios = useAxios();
  const { keycloak } = useKeycloak();

  const [sessionId, setSessionId] = useState<string>('');

  const startSession = useCallback(
    async (settings: SettingsType) => {
      if (sessionId) return;
      try {
        setIsLoading(true);
        const currentDate: Date = new Date();

        const session: SessionResponse = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions`,
          method: 'POST',
          data: {
            start_timestamp: currentDate,
            end_timestamp: currentDate,
            userId: keycloak.subject,
            botId: settings.botType,
          },
        });
        setSessionId(session.sessionId);
        return session.sessionId;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [axios, sessionId],
  );

  const updateSession = useCallback(
    async (payload: UpdateSessions) => {
      try {
        setIsLoading(true);

        await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${payload.sessionId}`,
          method: 'PATCH',
          data: payload,
        });
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [axios, sessionId],
  );

  const processSession = useCallback(
    async (payload: ProcessSession) => {
      try {
        setIsLoading(true);

        await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${payload.sessionId}/process`,
          method: 'POST',
          data: {
            target: payload.target,
            userId: payload.userId,
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [axios, sessionId],
  );

  const extractTextInsights = useCallback(
    async (sessionId: string) => {
      try {
        setIsLoading(true);

        const response = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${sessionId}/extract-text-insights`,
          method: 'POST',
        });

        return response;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [axios],
  );

  const getSession = useCallback(
    async (id: string): Promise<SessionDTO | null> => {
      try {
        setIsLoading(true);

        const rs: SessionDTO = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}`,
          method: 'GET',
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const getTextEmotions = useCallback(
    async (id: string): Promise<IResponseTextList | null> => {
      try {
        setIsLoading(true);

        const rs: IResponseTextList = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/text-emotions`,
          method: 'GET',
          params: {
            limit: 1000,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const getAudioEmotions = useCallback(
    async (id: string): Promise<IResponseAudioList | null> => {
      try {
        setIsLoading(true);

        const rs: IResponseAudioList = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/audio-emotions`,
          method: 'GET',
          params: {
            limit: 1000,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const getFacialEmotions = useCallback(
    async (id: string): Promise<IResponseFacialList | null> => {
      try {
        setIsLoading(true);

        const rs: IResponseFacialList = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/face-emotions`,
          method: 'GET',
          params: {
            limit: 1000,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const getAudioTextFeatures = useCallback(
    async (id: string): Promise<IResponseAudioFeaturesList | null> => {
      try {
        setIsLoading(true);

        const rs: IResponseAudioFeaturesList = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/audio-text-features`,
          method: 'GET',
          params: {
            limit: 1,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const getBlinkDetection = useCallback(
    async (id: string): Promise<IResponseBlinkDetectionList | null> => {
      try {
        setIsLoading(true);

        const rs: IResponseBlinkDetectionList = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/blink-detection`,
          method: 'GET',
          params: {
            limit: 1,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [axios],
  );

  const stopSession = useCallback(() => {
    setSessionId('');
  }, []);

  const getRobertaEmotions = useCallback(
    async (id: string): Promise<{ items: IRobertaEmotion[] }> => {
      try {
        setIsLoading(true);

        const rs: { items: IRobertaEmotion[] } = await axios?.request({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${id}/roberta-emotions`,
          method: 'GET',
          params: {
            limit: 1000,
          },
        });

        return rs;
      } catch (e) {
        console.error(e);
        return { items: [] }; // Return an empty array instead of null
      } finally {
        setIsLoading(false);
      }
    },
    [axios],
  );

  const chat = useCallback(
    async (
      sessionId: string,
      message: string,
      history: Record<'user' | 'bot', string>,
    ): Promise<ChatResponse> => {
      try {
        setIsLoading(true);

        const response = await axios?.request<ChatResponse>({
          url: `${process.env.REACT_APP_CHAT_API}/sessions/${sessionId}/chat`,
          method: 'POST',
          data: {
            message,
            history,
          },
        });

        return response; // Ensure we return the data property
      } catch (e) {
        console.error(e);
        return {
          response: {
            bot: 'An Error Occurred',
            user: '',
          },
        };
      } finally {
        setIsLoading(false);
      }
    },
    [axios],
  );

  return {
    sessionId,
    startSession,
    stopSession,
    updateSession,
    getSession,
    processSession,
    extractTextInsights,
    getFacialEmotions,
    getAudioEmotions,
    getTextEmotions,
    getAudioTextFeatures,
    getBlinkDetection,
    getRobertaEmotions,
    chat,
  };
};

export default useSession;
