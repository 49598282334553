import React, { useState, FunctionComponent } from 'react';

// Definirea tipurilor pentru prop-uri
interface ModalProps {
  title: string;
  handleOnSave: (text: string) => void;
  handleOnSkip: () => void;
}

const TextModal: FunctionComponent<ModalProps> = ({ title, handleOnSave, handleOnSkip }) => {
  const [text, setText] = useState<string>('');

  const isSaveButtonDisabled = text.length === 0;

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center'>
      <div className='bg-white p-5 rounded-lg shadow-lg w-full max-w-md'>
        <h2 className='text-lg font-bold mb-4'>{title}</h2>
        <textarea
          className='w-full p-2 border border-gray-300 rounded mb-4'
          placeholder='Enter your text here...'
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <div className='flex justify-end'>
          <button
            className='bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-700 disabled:opacity-50'
            disabled={isSaveButtonDisabled}
            onClick={() => handleOnSave(text)}
          >
            Save
          </button>
          <button
            className='bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700'
            onClick={handleOnSkip}
          >
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};

export default TextModal;
