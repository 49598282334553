import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ChatResponse } from '../../services/hooks/session/useSession.types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from './Chat.module.css';

interface ChatProps {
  sessionId: string;
  onSendMessage: (
    message: string,
    history: Record<'user' | 'bot', string>,
  ) => Promise<ChatResponse>;
}

interface ChatMessage {
  text: string;
  sender: 'user' | 'bot';
  timestamp: string;
}

const Chat: React.FC<ChatProps> = ({ sessionId, onSendMessage }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (!inputMessage.trim() || isLoading) return;

    setIsLoading(true);
    const newUserMessage: ChatMessage = {
      text: inputMessage,
      sender: 'user',
      timestamp: new Date().toISOString(),
    };

    setMessages((prev) => [...prev, newUserMessage]);
    setInputMessage('');

    try {
      // Create history object with last user and bot messages
      const isFirstMessage = messages.length === 0;
      const history: Record<'user' | 'bot', string> = isFirstMessage
        ? {
            user: '',
            bot: '',
          }
        : {
            user:
              messages
                .filter((msg) => msg.sender === 'user')
                .map((msg) => msg.text)
                .pop() || inputMessage,
            bot:
              messages
                .filter((msg) => msg.sender === 'bot')
                .map((msg) => msg.text)
                .pop() || '',
          };

      const response = await onSendMessage(inputMessage, history);

      if (response?.response?.bot) {
        const botMessage: ChatMessage = {
          text: JSON.parse(response.response.bot),
          sender: 'bot',
          timestamp: new Date().toISOString(),
        };
        setMessages((prev) => [...prev, botMessage]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className='flex flex-col h-full relative'>
      <div className='absolute inset-0 flex flex-col'>
        <div className='flex-1 overflow-y-auto p-4 space-y-4'>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-[70%] rounded-lg p-3 ${
                  message.sender === 'user' ? 'bg-blue-500' : 'bg-gray-100 text-gray-800'
                }`}
              >
                <div
                  className={
                    message.sender === 'user'
                      ? styles['markdown-content-user']
                      : styles['markdown-content']
                  }
                >
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.text}</ReactMarkdown>
                </div>
                <span
                  className={`text-xs mt-1 block ${
                    message.sender === 'user' ? 'text-blue-100' : 'text-gray-500'
                  }`}
                >
                  {new Date(message.timestamp).toLocaleTimeString()}
                </span>
              </div>
            </div>
          ))}
          {isLoading && (
            <div className='flex justify-start'>
              <div className='bg-gray-100 rounded-lg p-3'>
                <div className='animate-pulse'>Typing...</div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className='border-t p-4 bg-white'>
          <div className='flex items-center space-x-2'>
            <textarea
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder='Type a message...'
              className='flex-1 border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none max-h-32'
              rows={1}
            />
            <button
              onClick={handleSendMessage}
              disabled={isLoading || !inputMessage.trim()}
              className={`p-1 pr-2 w-10 h-10 rounded-full flex-shrink-0 ${
                isLoading || !inputMessage.trim()
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-500 hover:bg-blue-600'
              }`}
            >
              <FontAwesomeIcon icon={faPaperPlane} className='text-white' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
