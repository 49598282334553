import React, { useCallback, useState, useEffect } from 'react';
import { Column } from 'react-table';

import { Table } from '../../../components/table/Table';
import RecordingLinkCell from './recording-cell/RecordingCell';

import useAxios from '../../../services/axios/useAxios';
import DateCell from './date-cell/DateCell';
import ActionCell from './action-cell/ActionCell';
import { useKeycloak } from '@react-keycloak/web';
import { TextAnalysis } from '../../../services/hooks/session/useSession.types';
import { calculateOverallMood } from '../../../utils/sentiment';

interface SessionsResponse {
  totalRecords: number;
  sessions: Session[];
}

interface Session {
  sessionId: string;
  userId: string;
  name: string;
  email: string;
  comment: string;
  recordingLink?: string;
  recordedDate?: string;
  duration?: number;
  textAnalyses?: TextAnalysis[];
}

const formatDuration = (seconds?: number): string => {
  if (!seconds) return '-';

  // Round to nearest integer to avoid floating point issues
  seconds = Math.round(seconds);

  if (seconds < 60) {
    return `${seconds}s`;
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  if (hours > 0) {
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  }

  return `${minutes}m ${remainingSeconds}s`;
};

const MoodCell = ({ value: textAnalyses }: { value: TextAnalysis[] }) => {
  const mood = calculateOverallMood(textAnalyses);
  const color =
    mood === 'Positive'
      ? '#55BF3B'
      : mood === 'Negative'
      ? '#DF5353'
      : mood === '-'
      ? '#666'
      : '#DDDF0D';

  return <span style={{ color, fontWeight: 'bold' }}>{mood}</span>;
};

export default function Sessions({ userId }: { userId: string }) {
  const sessionColumns: Column<Session | any>[] = [
    {
      Header: 'Session date',
      accessor: 'recordedDate',
      Cell: DateCell,
    },
    {
      Header: 'Duration',
      accessor: 'duration',
      Cell: ({ value }) => <>{formatDuration(value)}</>,
    },
    {
      Header: 'Overall Mood',
      accessor: 'textAnalyses',
      Cell: MoodCell,
    },
    {
      Header: 'Comment',
      accessor: 'comment',
    },
    {
      Header: 'Preview',
      accessor: 'recordingLink',
      Cell: RecordingLinkCell,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <ActionCell row={row} onDelete={() => handleDelete(row.original.sessionId)} />
      ),
    },
  ];

  const {
    keycloak: { authenticated },
  } = useKeycloak();

  const [sessionData, setSessionData] = useState<Session[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const axios = useAxios();

  const fetchData = useCallback(
    async (params?: any) => {
      if (!authenticated) return;
      try {
        setIsLoading(true);
        const queryParams = new URLSearchParams();
        queryParams.append('rowsPerPage', params?.rowsPerPage || 25);
        queryParams.append('page', params?.page);
        queryParams.append('includeMetrics', String(params?.includeMetrics ?? true));
        if (userId) {
          queryParams.append('user', userId);
        }

        const response: SessionsResponse = await axios.request({
          method: 'GET',
          url: `${process.env.REACT_APP_CHAT_API}/sessions?${queryParams.toString()}`,
        });
        setSessionData(response.sessions || []);
        setTotalItems(response.totalRecords || 0);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [axios, userId, authenticated],
  );

  const handleDelete = useCallback((deletedSessionId: string) => {
    setRefreshKey((prevKey) => prevKey + 1);
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className='mt-8 bg-white rounded-lg shadow'>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className='w-full px-6 py-4 flex items-center justify-between text-lg font-semibold text-gray-700 hover:bg-gray-50 rounded-t-lg'
      >
        <span>Session History</span>
        <svg
          className={`w-5 h-5 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
        </svg>
      </button>

      {isExpanded && (
        <div className='px-6 pb-6'>
          <Table
            columns={sessionColumns}
            data={sessionData}
            totalRecords={totalItems}
            fetchFunction={fetchData}
            isLoading={loading}
            refreshKey={refreshKey}
            rowsPerPage={10}
          />
        </div>
      )}
    </div>
  );
}
